import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/tunisia-jobs-5-8.png";
import TunisiaJobs5 from "../assets/img/tunisia-jobs-5-5.png";
import TunisiaJobs7 from "../assets/img/tunisia-jobs-5-7.png";
import ATF from "../assets/img/ATF.jpg";

const AccessFinance = () => (
  <Layout>
    <SEO
      title="Financial Access | Visit Tunisia Project"
      description="Financial Access"
    />
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-accesstofinance-2"
            className="block block-block-content block-block-content264600af-1276-446f-8120-0ae6940f15e0"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={TunisiaJobs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Access to Finance
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Providing <span>financial solutions</span> for SMEs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={56}
              role="article"
              about="/en/access-finance"
              className="node node--type-activities node--promoted node--view-mode-full"
            >
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <div className="inner-card">
                                <h3>The Sector</h3>
                                <p>
                                  As in many countries, limited access to
                                  finance is a major impediment SME' growth.
                                  include the inefficiency of the banking
                                  system, traditional collateral-based lending,
                                  and a dearth of innovative financial
                                  instruments capable of reaching smaller firms.
                                </p>
                              </div>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay
                            >
                              <div className="inner-card">
                                <h3>JOBS’ Vision and Strategy</h3>
                                <p>
                                  Tunisia JOBS assists the financial sector
                                  (including banks, microenterprise, fintech,
                                  and leasing institutions) to streamline the
                                  credit/investment process, including the
                                  introduction of digital and other innovative
                                  technologies. Our aim is to significantly
                                  increase access to capital for SMEs as well as
                                  microenterprises
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="our-work">
                      <div className="brick brick--type--our-work-workforce brick--id--67 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Our Work
                          </div>
                        </h2>
                        <div className="aos-animate" data-aos="fade-up">
                          <p>
                            In addition to{" "}
                            <a href="covid-19-response.html" target="_blank">
                              responding to the pandemic
                            </a>
                            , JOBS’ work is focused in three key areas:
                            <br />
                            (1) making the lending processes of leading
                            financial institutions easier, more effective, and
                            faster, and thus accelerating credit to SMEs; (2)
                            helping to develop new innovative digital tools; and
                            (3) facilitating investments to SMEs by improving
                            deal flow and transparency.
                          </p>
                        </div>
                        <div className="work-card-wrapper">
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={TunisiaJobs5} alt="" />
                            <div className="about-work">
                              <h5>
                                Making the lending process easier, more
                                effective, and faster
                              </h5>
                              <p>
                                JOBS is providing support to financial
                                institutions to improve their credit processes
                                and to develop digital tools that facilitate
                                increased lending. We are also supporting banks
                                to adopt credit-process and risk-management
                                improvements, establish benchmarking criteria
                                for loans, and develo new scorecards to review
                                credit applications. JOBS is also supporting
                                financial partners to enhance their value chain
                                (VC) financing model by creating innovative,
                                scalable new features for VC loans.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={ATF} alt="" />
                            <div className="about-work">
                              <h5>
                                Helping to develop new innovative digital tools
                              </h5>
                              <p>
                                JOBS is helping partners to develop digital
                                applications and fintech products to support
                                their digital transformation and help them reach
                                new SME customers throughout Tunisia. For
                                example, JOBS supports Advans Tunisie as well as
                                Attijari Bank to launch digital lending
                                platforms for Baobab, Attijari Bank, and Tunisie
                                Leasing and Factoring (TLF). JOBS is working
                                with government and private sector stakeholders
                                to help Tunisians implement crowdfunding to
                                finance a project or venture by raising small
                                amounts of money from a large number of people,
                                typically through the internet.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={TunisiaJobs7} alt="" />
                            <div className="about-work">
                              <h5>
                                Facilitating investments to SMEs by improving
                                deal flow, transparency, and governance
                              </h5>
                              <p>
                                JOBS has supported a consortium of stakeholders
                                including the Ministry of Finance, the Caisse
                                des Dépôts et Consignations (CDC), and the
                                Tunisian Private Equity Association (ATIC) to
                                create a new, more efficient, privately managed
                                model to significantly increase regional
                                investment. JOBS is also supporting the CDC,
                                ATIC, and the Tunisian Stock Market (BVMT), to
                                design and develop the first online investment
                                platform in Tunisia{" "}
                                <a href="../index-1.htm" target="_blank">
                                  JoussourInvest.tn
                                </a>
                                , linking SMEs with fund managers to accelerate
                                investment in SMEs. Finally, JOBS has also
                                helped the CDC to adopt new instruments to
                                better measure the social impact of its
                                investments.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--68 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2></h2>
                        <ul className="pl-0">
                          <li>
                            <span>
                              <p>
                                495 million TND facilitated in debt and equity
                                funding for over 5,467 Micro, Small, and Medium
                                Enterprises (MSMEs)
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p> 33 equity investments for +43 million TND</p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p>
                                108 million TND in increased investments by CDC
                                investments
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p>
                                246 million TND for SMEs from partner financial
                                institutions as a result of streamlined credit
                                risk processes and improved bank credit risk
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p>53 million TND in increased lease financing</p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p>
                                9 million TND in new lending for members of the
                                Tunisian Textile Federation
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <p>
                                <a
                                  href="covid-19-response.html"
                                  target="_blank"
                                >
                                  See here{" "}
                                </a>{" "}
                                specific activities related to
                                <span> JOBS’ COVID-19 Response</span>
                              </p>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);
export default AccessFinance;
